import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';

const PromotionsContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  background-color: ${colors.alabaster};
  padding-top: 60px;
  padding-bottom: 60px;
`;

export default PromotionsContainer;
