import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextButton from 'pwm-components/components/TextButton';
import { DropdownMenuItem } from '../../../baseComponents/Dropdown/DropdownMenuItem.styles';
import {
  CopyrightText,
  CurrentLanguage,
  ExpandLessIcon,
  FooterContainer,
  FooterContainer2,
  FooterLink,
  FooterLinksContainer,
  LanguageDropup,
  LanguageSelector,
  LanguageSelectorContainer,
} from './Footer.styles';
import { languages, LocaleContext } from '../../../locale/LocaleContainer';

const findLocale = (loc: string): string => {
  const localeLowercase = loc.toLowerCase();
  const localeShort = localeLowercase.substring(0, 2);

  return (localeShort === 'pt' || localeShort === 'zh')
    ? localeLowercase
    : localeShort;
};

export enum direction {
  up = '180deg',
  down = '0deg',
}

const Footer: React.FC = () => {
  const { locale: currLocale, setLocale } = useContext(LocaleContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    const hideDropdown = (): void => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('click', hideDropdown);
    }

    return () => document.removeEventListener('click', hideDropdown);
  }, [showDropdown]);
  return (
    <FooterContainer>
      <FooterContainer2>
        <LanguageSelectorContainer>
          <LanguageSelector
            onClick={() => setShowDropdown(!showDropdown)}
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            <CurrentLanguage>
              {languages[currLocale as keyof typeof languages].language}
            </CurrentLanguage>
            <ExpandLessIcon
              direction={showDropdown ? direction.up : direction.down}
              isHover={isHover}
            />
          </LanguageSelector>
          <LanguageDropup align="left" hidden={!showDropdown} arrowSize={3}>
            {Object.values(languages).map(({ locale, language }) => (
              <DropdownMenuItem key={locale} variant="dark">
                <TextButton onClick={() => setLocale(locale)}>
                  {language}
                </TextButton>
              </DropdownMenuItem>
            ))}
          </LanguageDropup>
        </LanguageSelectorContainer>
        <FooterLinksContainer>
          {
            <>
              <FooterLink
                href={`https://www.avira.com/${findLocale(
                  currLocale,
                )}/general-imprint`}
                target="_blank"
              >
                <FormattedMessage
                  id="footer.Imprint"
                  defaultMessage="Imprint"
                />
              </FooterLink>
              <FooterLink
                href={`https://www.avira.com/${findLocale(
                  currLocale,
                )}/general-privacy`}
                target="_blank"
              >
                <FormattedMessage
                  id="footer.Privacy"
                  defaultMessage="Privacy"
                />
              </FooterLink>
              <FooterLink
                href={`https://www.avira.com/${findLocale(
                  currLocale,
                )}/legal-terms`}
                target="_blank"
              >
                <FormattedMessage id="footer.Legal" defaultMessage="Legal" />
              </FooterLink>
              <CopyrightText>
                <FormattedMessage
                  id="footer.Copyrighted"
                  defaultMessage="© 2025 Avira Holding GmbH, part of Gen Digital Inc. All rights reserved."
                />
              </CopyrightText>
            </>
          }
        </FooterLinksContainer>
      </FooterContainer2>
    </FooterContainer>
  );
};

export default Footer;
