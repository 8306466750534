import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../baseComponents/variables/font';
import LineHeightEnum from '../../baseComponents/variables/lineHeight';

export const Heading = styled('div')`
  text-align: center;
  padding-top: 44px;
  max-width: 1000px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doubleGreatPrimer};
  line-height: ${LineHeightEnum.doubleEnglish};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
`;

export const StyledText = styled('span')`
   color: #D40404;
   font-family: 'Kievit Bold';
`;
export const ExternalLink = styled('a')`
   color: #0F71F0;
   white-space: nowrap;
`;
export const InstallNowButton = styled('button')`
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 64px;
  background-color: ${colors.navyblue};
  border: none;
  border-radius: 2px;
  cursor: pointer;
`;

export const MigrationHeadingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingInfo = styled('div')`
  text-align: center;
  width: 750px;
  padding-top: 16px;
  font-family: ${FontFamily.Kievit};
  font-size: ${FontSizes.english};
  line-height: ${LineHeightEnum.paragon};
  font-weight: ${FontWeight.medium};
  color: ${colors.shark};
`;

export const StatusTextHeading = styled('div')`
  text-align: center;
  margin-top: 40px;
  padding-top: 12px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doublePica};
  line-height: ${LineHeightEnum.doublePica};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.navyblue};
`;