import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

export const InfoStepHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doubleColumbianExchange};
  line-height: ${LineHeightEnum.quadrupleColumbian};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
  width: 516px;
  margin-bottom: 21px;
`;
