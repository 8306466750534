import React, { useContext } from 'react';
import { Box } from 'pwm-components/components/Box';
import { InfoContainer } from './Info.styles';
import InfoStep, { flowDirection } from './InfoStep';
import infoWindowOne from '../../../img/browserMigrationInfoWindowOne.svg';
import infoWindowTwo from '../../../img/browserMigrationInfoWindowTwo.svg';
import redCart from '../../../img/redCart.svg';
import magicClick from '../../../img/magicClick.svg';
import { LocaleContext } from '../../../locale/LocaleContainer';

const Info: React.FC = () => {
  const { locale: currLocale } = useContext(LocaleContext);
  return (
    <InfoContainer>
      <InfoStep
        imagePosition={flowDirection.right}
        imageURL={infoWindowOne}
        headingID='browserSafetyMigration.info.step.one.heading'
        headingDefaultMessage='All the benefits, right from your browser'
        infoIDs={[
          'browserSafetyMigration.info.step.one.info.one',
          'browserSafetyMigration.info.step.one.info.two',
          'browserSafetyMigration.info.step.one.info.three',
          'browserSafetyMigration.info.step.one.info.four',
        ]}
        infoDefaultMessages={[
          'Stop malicious and phishing websites',
          'Prevent companies from tracking you',
          'Prevent browser hijackings',
          'Detect unwanted apps in your downloads',
        ]}
        infoImages={[
          <Box marginRight={6} key={0} position='relative' top='8px'>
            <img src={redCart} alt='Avira Safe Shopping' />
          </Box>,
        ]}
        posAdjust={['0', '0', '0']}
      />
      <InfoStep
        imagePosition={flowDirection.left}
        imageURL={infoWindowTwo}
        headingID='browserSafetyMigration.info.step.two.heading'
        headingDefaultMessage='Make Avira Browser Safety your own'
        infoIDs={[
          'browserSafetyMigration.info.step.two.info.one',
        ]}
        infoDefaultMessages={[
          'You can tailor Avira Browser Safety to your needs—just switch individual features on or off.',
        ]}
        infoImages={[
          <Box
            key={0}
            marginLeft={currLocale === 'ja-JP' ? 0 : 8}
            marginRight={8}
            position='relative'
            top='5px'
          >
            <img src={magicClick} alt='img' />
          </Box>,
          <></>,
          <></>,
        ]}
        posAdjust={['0', '0', '0']}
      />
    </InfoContainer>
  );
};

export default Info;
