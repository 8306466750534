import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';
import AviraIcon from 'pwm-components/icons/Avira';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import logoPWM1x from '../../img/main/logoPWM@1x.png';
import logoPWM2x from '../../img/main/logoPWM@2x.png';
import logoPWM3x from '../../img/main/logoPWM@3x.png';

import logoABS1x from '../../img/main/logoABS@1x.png';
import logoABS2x from '../../img/main/logoABS@2x.png';
import logoABS3x from '../../img/main/logoABS@3x.png';

import logoSS1x from '../../img/main/logoSS@1x.png';
import logoSS2x from '../../img/main/logoSS@2x.png';
import logoSS3x from '../../img/main/logoSS@3x.png';

import { useBrowserConfig } from '../hooks';

import Background from '../Background';
import BrowserNotSupported from './BrowserNotSupported';
import ExtensionCard from './ExtensionCard';

const AviraLogo = styled(AviraIcon)`
  width: 36px;
  height: 36px;
`;

const Main = () => {
  const { locale } = useIntl();
  const { supported } = useBrowserConfig();

  useEffect(() => {
    document.title = 'Avira - Extension Onboarding';
  }, []);

  return (
    <Background>
      <Box
        pb="xxxl"
        px="xxl"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box mb="m">
          <AviraLogo size="large" variant="alert" />
        </Box>
        <Paragraph mb="s" size="h1" textAlign="center">
          <FormattedMessage
            id="app.mainHeading"
            defaultMessage="Add the Avira extensions for full protection"
          />
        </Paragraph>
        <Paragraph size="h3" textAlign="center">
          <strong>
            <FormattedMessage
              id="app.secondHeading"
              defaultMessage="Get full privacy protection directly in your browser"
            />
          </strong>
        </Paragraph>
        <Box
          mt="xl"
          width="100%"
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <ExtensionCard
              type="pwm"
              seal={locale === 'de'}
              icon={(
                <img
                  src={logoPWM1x}
                  srcSet={`${logoPWM1x} 1x, ${logoPWM2x} 2x, ${logoPWM3x} 3x`}
                  alt="Avira"
                  width="36"
                  height="36"
                />
              )}
              title={(
                <FormattedMessage
                  id="passwordManagerExtension.cardHeading"
                  defaultMessage="PASSWORD MANAGER"
                />
              )}
              description={(
                <FormattedMessage
                  id="passwordManagerExtension.textNotInstalled"
                  defaultMessage="Saves, manages, and syncs all your passwords across all your devices"
                />
              )}
            />
            <ExtensionCard
              type="abs"
              icon={(
                <img
                  src={logoABS1x}
                  srcSet={`${logoABS1x} 1x, ${logoABS2x} 2x, ${logoABS3x} 3x`}
                  alt="Avira"
                  width="36"
                  height="36"
                />
              )}
              description={(
                <FormattedMessage
                  id="browserSafetyExtension.textNotInstalled"
                  defaultMessage="Protects you from malicious websites and invisible trackers"
                />
              )}
              title={(
                <FormattedMessage
                  id="browserSafetyExtension.cardHeading"
                  defaultMessage="BROWSER SAFETY"
                />
              )}
            />
            <ExtensionCard
              type="sse"
              icon={(
                <img
                  src={logoSS1x}
                  srcSet={`${logoSS1x} 1x, ${logoSS2x} 2x, ${logoSS3x} 3x`}
                  alt="Avira"
                  width="36"
                  height="36"
                />
              )}
              description={(
                <FormattedMessage
                  id="safeShoppingExtension.textNotInstalled"
                  defaultMessage="Never miss out on best deals while shopping online safely by enabling price comparison"
                />
              )}
              title={(
                <FormattedMessage
                  id="safeShoppingExtension.cardHeading"
                  defaultMessage="SAFE SHOPPING"
                />
              )}
            />
          </Box>
          {!supported && <BrowserNotSupported />}
        </Box>
      </Box>
    </Background>
  );
};

export default Main;
