import React, { useEffect } from 'react';
import BackgroundContainer from '../safeShopping/layoutComponents/BackgroundContainer';
import MigrationHeading from './MigrationHeading';
import MigrationBody from './migrationBody/MigrationBody';

const BrowserSafetyMigrationPage: React.FC = () => {
  useEffect( () => {
    document.title = "Avira - Browser Safety Migration"
  }, [])
  return (
    <BackgroundContainer>
      <MigrationHeading />
      <MigrationBody />
    </BackgroundContainer>
  );
};

export default BrowserSafetyMigrationPage;
