import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  InfoStepBullet,
  InfoStepContainer,
  InfoStepData,
  InfoStepInfo,
} from '../../safeShopping/migrationBody/InfoStep.styles';

import { InfoStepHeading } from './InfoStep.styles';

export enum flowDirection {
  left = 'row',
  right = 'row-reverse',
}

const InfoStep: React.FC<{
  imageURL: string;
  imagePosition: flowDirection;
  headingID: string;
  headingDefaultMessage: string;
  infoIDs: string[];
  infoDefaultMessages: string[];
  infoImages: React.JSX.Element[];
  posAdjust: string[];
}> = ({
  imageURL,
  imagePosition,
  headingID,
  headingDefaultMessage,
  infoIDs,
  infoDefaultMessages,
  infoImages,
  posAdjust,
}) => (
  <InfoStepContainer direction={imagePosition}>
    <img src={imageURL} alt="info" />
    <div>
      <InfoStepHeading>
        <FormattedMessage
          id={headingID}
          defaultMessage={headingDefaultMessage}
        />
      </InfoStepHeading>
      {infoIDs.map((infoID, index) => (
        <InfoStepInfo key={index}>
          <InfoStepBullet>
            <FormattedMessage
              id="migration.info.step.two.info.bullet"
              defaultMessage="•"
            />
          </InfoStepBullet>
          <InfoStepData posAdjust={posAdjust[index]}>
            <FormattedMessage
              id={infoID}
              defaultMessage={infoDefaultMessages[index]}
              values={{
                image: infoImages[index] ?? null,
              }}
            />
          </InfoStepData>
        </InfoStepInfo>
      ))}
    </div>
  </InfoStepContainer>
);

export default InfoStep;
