import { createGlobalStyle } from 'styled-components';
import KievitBold from '../fonts/KievitCompPro-Xbold.ttf';

const LocalFontStyles = createGlobalStyle`
  @font-face {
  font-family: 'Kievit Bold';
  src: url(${KievitBold}) format('truetype');
}
`;

export default LocalFontStyles;
