import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PromotionsContainer from './MigrationBody.styles';
import {
  Banner,
  MigrationBodyContainer,
} from '../../safeShopping/migrationBody/MigrationBody.styles';
import extension from '../../../img/extension.svg';
import cursor from '../../../img/cursor.svg';
import store from '../../../img/shield-globe.svg';
import rightArrow from '../../../img/rightArrow.svg';
import bottomArrow from '../../../img/bottomArrow.svg';
import BannerStep from '../../safeShopping/migrationBody/BannerStep';
import Info from './Info';
import { InfoHeading } from '../../safeShopping/migrationBody/Info.styles';

const RightArrow = styled('img').attrs(() => ({ alt: '->' }))`
    margin-left: 48px;
    margin-right: 48px;
    content: url(${rightArrow});
`;

const BottomArrow = styled('img').attrs(() => ({ alt: 'v' }))`
  height: 50px;
  width: 50px;
  content: url(${bottomArrow});
`;

const BottomArrowButton = styled('button')`
  position: relative;
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  top: -25px;
  height: 50px;
  width: 50px;
`;

const MigrationBody: React.FC = () => {
  const bannerItems = [
    {
      headingID: 'migration.banner.step.one.heading',
      headingDefaultMessage: 'STEP 1',
      infoID: 'migration.banner.step.one.info',
      infoDefaultMessage: 'Click <strong>Install now</strong>',
      imageURL: cursor,
    },
    {
      headingID: 'migration.banner.step.two.heading',
      headingDefaultMessage: 'STEP 2',
      infoID: 'migration.banner.step.two.info',
      infoDefaultMessage: 'Add extension from the Edge Add-ons',
      imageURL: extension,
    },
    {
      headingID: 'migration.banner.step.three.heading',
      headingDefaultMessage: 'STEP 3',
      infoID: 'browserSafetyMigration.banner.step.three.info',
      infoDefaultMessage: 'Surf securely',
      imageURL: store,
    },
  ];
  return (
    <MigrationBodyContainer>
      <Banner>
        {bannerItems.map((item, index) => (
          <React.Fragment key={index}>
            <BannerStep
              imageURL={item.imageURL}
              headingID={item.headingID}
              headingDefaultMessage={item.headingDefaultMessage}
              infoID={item.infoID}
              infoDefaultMessage={item.infoDefaultMessage}
            />
            {index < bannerItems.length - 1 && <RightArrow />}
          </React.Fragment>
        ))}
      </Banner>
      <PromotionsContainer>
        <InfoHeading>
          <FormattedMessage
            id="migration.info.heading"
            defaultMessage="How it works?"
          />
        </InfoHeading>
      </PromotionsContainer>
      <BottomArrowButton
        onClick={() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }}
      >
        <BottomArrow />
      </BottomArrowButton>
      <Info />
    </MigrationBodyContainer>
  );
};

export default MigrationBody;
